import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./login";
import {Private} from "./private/layout";
import {useAuth} from "./provider/authProvider";

export const AdminRoutes = () => {
    const {user} = useAuth()
  return (
    <Routes>
      <Route path="/login" element={!user? <Login />: <Navigate to="/" replace />} />
      <Route path="/" element={user?<Private />:  <Navigate to="/login" replace /> } />
      {/* Add more protected routes as needed */}
    </Routes>
  );
};
