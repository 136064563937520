// PrivateRoute.js
import { Navigate, useLocation } from 'react-router-dom';
import {useAuth} from '../provider/authProvider';
import AdminDashboard from './admindash';

export const PrivateRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();
    const location = useLocation();

    if (isLoading) {
        return <div>Loading...</div>; // Or your loading component
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export const Private = ()=>{
    return(
        <PrivateRoute>
            <AdminDashboard/>
        </PrivateRoute>
    )
}