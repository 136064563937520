import './App.css';
import { BrowserRouter as Router} from "react-router-dom";
import {AuthProvider} from './components/provider/authProvider';
import {AdminRoutes} from './components/route';

function App() {
  return (
    <div className="App">
        <Router>
            <AuthProvider>
                <AdminRoutes/>
            </AuthProvider>
        </Router>
    </div>
  );
}

export default App;


/**
 * create new driver (POST): name, phone, slots, lga,state, vehicleType, passengers
 *        show response: driver id, name, phone, code, pwaGetCode
 * upload new ad (POST): mediaLink, state, lga, businessName, fleet, days 
 *        show response: ad, businessurl
 * get all ads (GET):
 * alladsassignment (GET): 
 */