import React, { useState, useEffect } from "react";
import StatsViewer from "./statsmodal";
import {useAuth} from "../provider/authProvider";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";
// import AssignmentsView from "./allassignment";

const AdminDashboard = () => {
  const [ads, setAds] = useState([]);
  const [drivers, setDrivers] = useState([]);
  // const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("ads");
  const [statsViewer, setStatsViewer] = useState(null);
  const [newDriver, setNewDriver] = useState({
    name: "",
    phone: "",
    slots: "",
    lga: "",
    state: "",
    vehicleType: "",
    passengers: "",
  });
  const [newAd, setNewAd] = useState({
    businessName: "",
    state: "",
    lga: "",
    startDate: "",
    fleet: "",
    days: "",
    mediaLink: "",
    mediaLength: "",
    mediaType: "",
  });

  const {logout} = useAuth()
  useEffect(() => {
    fetchData();
  }, []);

  const DownloadResponse = (data) => {
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: "text/plain",
    });
    const url = URL.createObjectURL(blob);
    // Create a link element and trigger a download
    const a = document.createElement("a");
    a.href = url;
    a.download = data?.data?.driver?.driverId ? data.data.driver.driverId +".txt"  : data?.data?.ad.businessName+".txt"; // File name
    document.body.appendChild(a);
    a.click();
    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const [
        adsRes,
        driversRes,
        // assignmentsRes
      ] = await Promise.all([
        fetch("/allAds"),
        fetch("/alldrivers"),
        // fetch("/alladsassignment"),
      ]);

      const [
        adsData,
        driversData,
        // assignmentsData
      ] = await Promise.all([
        adsRes.json(),
        driversRes.json(),
        // assignmentsRes.json(),
      ]);
      setAds(adsData.data.ads);
      setDrivers(driversData.data.driver);
      // setAssignments(assignmentsData.data.alladsassignment);
    } catch (err) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const handleAddDriver = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/addDriver", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newDriver),
      });
      const data = await response.json();
      if (data.status === "success") {
        DownloadResponse(data);
        fetchData();
        setNewDriver({
          name: "",
          phone: "",
          slots: "",
          lga: "",
          state: "",
          vehicleType: "",
          passengers: "",
        });
      }
    } catch (err) {
      setError("Failed to add driver");
    }
  };
  const handleGetPwaCode = async (e) => {
    e.preventDefault();
    let driverId = e.target[0].value;
    try {
      const response = await fetch("/generatedriverpwacode/" + driverId, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "success") {
        e.target[0].value = e.target[0].value + "," +" "+ data.data.pwaGetCode;
      }
    } catch (err) {
      setError("Failed to add driver");
    }
  };

  const handleAddAd = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/newAd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...newAd,
          fleet: parseInt(newAd.fleet),
          days: parseInt(newAd.days),
        }),
      });
      const data = await response.json();
      if (data.status === "success") {
        DownloadResponse(data);
        fetchData();
        setNewAd({
          mediaLink: "",
          state: "",
          lga: "",
          businessName: "",
          fleet: "",
          days: "",
          startDate: "",
          mediaLength: "",
          mediaType: "",
        });
      }
    } catch (err) {
      setError("Failed to add ad");
    }
  };

  //suspend
  const handleSuspendAd = async (adId, suspended) => {
    try {
      await fetch(`/${suspended ? "unsuspendAd" : "suspendAd"}/${adId}`, {
        method: "PUT",
      });
      fetchData();
    } catch (err) {
      setError("Failed to suspend ad");
    }
  };
  const handleSuspendDriver = async (driverId, suspended) => {
    try {
      await fetch(
        `/${suspended ? "unsuspendDriver" : "suspendDriver"}/${driverId}`,
        {
          method: "PUT",
        }
      );
      fetchData();
    } catch (err) {
      setError("Failed to suspend ad");
    }
  };

  const handleDeleteAd = async (adId) => {
    try {
      await fetch(`/deleteAd/${adId}`, {
        method: "DELETE",
      });
      fetchData();
    } catch (err) {
      setError("Failed to delete ad");
    }
  };
  const inverseCalculateFleetAndDays = (assignments) => {
    if (assignments.length === 0) {
      return { fleets: 0, days: 0 };
    }

    const driversPerFleet = 5;
    const fleets = assignments[0].drivers / driversPerFleet;

    let totalDays = 0;
    for (let assignment of assignments) {
      totalDays += assignment.days * (assignment.rep + 1);
    }

    return { fleets, days: totalDays };
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 pb-10">
      <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>
      <button
      onClick={logout}
      className="fixed top-4 right-4 bg-red-500 text-white font-semibold py-2 px-4 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
    >
      Logout
    </button>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4 relative">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <div className="mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            {[
              "ads",
              "drivers",
              // "assignments"
            ].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`${
                  activeTab === tab
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm mx-6 first:ml-0`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {activeTab === "ads" && (
        <div>
          <div className="bg-white shadow rounded-lg mb-6">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Add New Ad
              </h3>
              <form onSubmit={handleAddAd} className="space-y-4">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {Object.entries(newAd).map(([key, value]) => (
                    <div key={key}>
                      <input
                        type={
                          key === "startDate"
                            ? "date"
                            : key === "fleet" ||
                              key === "days" ||
                              key === "mediaLength"
                            ? "number"
                            : "text"
                        }
                        required
                        placeholder={
                          key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/([A-Z])/g, " $1") +
                          ": " +
                          `${key === "mediaType" ? "image, video, audio" : ""}`
                        }
                        value={value}
                        onChange={(e) =>
                          setNewAd({ ...newAd, [key]: e.target.value })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  ))}
                </div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Add Ad
                </button>
              </form>
            </div>
          </div>

          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-4 py-5 sm:px-6">
              <div className="bg-blue-50 p-4 rounded-lg">
                <h3 className="text-lg leading-6 text-blue-700 font-semibold ">
                  All Ads
                </h3>
                <p className="text-2xl font-bold">{ads.length}</p>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "Biz Name",
                      "State",
                      "LGA",
                      "Fleet",
                      "Days",
                      "MAxForComAssg",
                      "ComAssg",
                      "start",
                      "completed",
                      "completely Assigned",
                      "Actions",
                    ].map((header) => (
                      <th
                        key={header}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {ads.map((ad) => (
                    <tr key={ad._id}>
                      <td
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 cursor-pointer"
                        onClick={() =>
                          setStatsViewer({
                            type: "ad",
                            adId: ad._id,
                            businessName: ad.businessName,
                          })
                        }
                      >
                        {ad.businessName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {ad.state}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {ad.lga}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {inverseCalculateFleetAndDays(ad.slots).fleets}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {inverseCalculateFleetAndDays(ad.slots).days}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {ad.maxForCompletedAssignment}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {ad.CurrentCompletedAssignment}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            new Date(ad.startDate).getTime() > Date.now()
                              ? "bg-red-100 text-red-800"
                              : "bg-green-100 text-green-800"
                          }`}
                        >
                          {new Date(ad.startDate).getTime() > Date.now()
                            ? new Date(ad.startDate).toLocaleDateString()
                            : "Started"}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            !ad.completed
                              ? "bg-red-100 text-red-800"
                              : "bg-green-100 text-green-800"
                          }`}
                        >
                          {!ad.completed ? "false" : "true"}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            !ad.completelyAssigned
                              ? "bg-red-100 text-red-800"
                              : "bg-green-100 text-green-800"
                          }`}
                        >
                          {!ad.completelyAssigned ? "false" : "true"}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button
                          onClick={() => handleSuspendAd(ad._id, ad.suspended)}
                          className="text-blue-600 hover:text-blue-900 mr-4"
                        >
                          {ad.suspended ? "Unsuspend" : "Suspend"}
                        </button>
                        <button
                          onClick={() => handleDeleteAd(ad._id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {activeTab === "drivers" && (
        <div>
          <div className="bg-white shadow rounded-lg mb-6">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Add New Driver
              </h3>
              <form onSubmit={handleAddDriver} className="space-y-4">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {Object.entries(newDriver).map(([key, value]) => (
                    <div key={key}>
                      <input
                        type={["slots","phone","passengers"].includes(key)? "number":"text"}
                        required
                        placeholder={
                          key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/([A-Z])/g, " $1")
                        }
                        value={value}
                        onChange={(e) =>
                          setNewDriver({ ...newDriver, [key]: e.target.value })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  ))}
                </div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Add Driver
                </button>
              </form>
            </div>
          </div>
          <div className="bg-white shadow rounded-lg mb-6">
            <div className="px-4 py-5 sm:p-6 ">
              <h3>Get Pwa Code</h3>
              <form
                onSubmit={handleGetPwaCode}
                className="space-y-4 flex flex-row gap-5 items-center"
              >
                <input
                  type="text"
                  placeholder={"DriverId"}
                  className="mt-2 block rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm w-max h-max"
                />
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 h-full"
                >
                  Generate Code
                </button>
              </form>
            </div>
          </div>
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                All Drivers {" ("} {drivers.length} {")"}
              </h3>
              <div className="bg-green-50 p-4 rounded-lg">
                <h4 className="text-green-700 font-semibold">Active Drivers</h4>
                <p className="text-2xl font-bold">
                  {drivers.filter((d) => !d.suspended).length}
                </p>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "Driver ID",
                      "Name",
                      "Phone",
                      "State",
                      "LGA",
                      "Passengers",
                      "Completed Days",
                      "Status",
                      "Actions",
                    ].map((header) => (
                      <th
                        key={header}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {drivers.map((driver) => (
                    <tr key={driver.driverId}>
                      <td
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 cursor-pointer"
                        onClick={() =>
                          setStatsViewer({
                            type: "driver",
                            driverId: driver.driverId,
                          })
                        }
                      >
                        {driver.driverId}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {driver.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {driver.phone}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {driver.state}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {driver.lga}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {driver.passengers}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {driver.completedDays}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            driver.suspended
                              ? "bg-red-100 text-red-800"
                              : "bg-green-100 text-green-800"
                          }`}
                        >
                          {driver.suspended ? "Suspended" : "Active"}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button
                          onClick={() =>
                            handleSuspendDriver(
                              driver.driverId,
                              driver.suspended
                            )
                          }
                          className="text-blue-600 hover:text-blue-900 mr-4"
                        >
                          {driver.suspended ? "Unsuspend" : "Suspend"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {/* {activeTab === "assignments" && <AssignmentsView initialAds={ads}/> } */}
      {/*// (
      //   <div className="bg-white shadow rounded-lg overflow-hidden">
      //     <div className="px-4 py-5 sm:px-6">
      //       <h3 className="text-lg leading-6 font-medium text-gray-900">
      //         Ad Assignments
      //       </h3>
      //     </div>
      //     <div className="overflow-x-auto">
      //       <table className="min-w-full divide-y divide-gray-200">
      //         <thead className="bg-gray-50">
      //           <tr>
      //             {[
      //               "Ad ID",
      //               "Driver ID",
      //               "Status",
      //               "Start Date",
      //               "End Date",
      //             ].map((header) => (
      //               <th
      //                 key={header}
      //                 className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      //               >
      //                 {header}
      //               </th>
      //             ))}
      //           </tr>
      //         </thead>
      //         <tbody className="bg-white divide-y divide-gray-200">
      //           {assignments.map((assignment) => (
      //             <tr key={assignment._id}>
      //               <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
      //                 {assignment.adId}
      //               </td>
      //               <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      //                 {assignment.driverId}
      //               </td>
      //               <td className="px-6 py-4 whitespace-nowrap">
      //                 <span
      //                   className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
      //                     assignment.status === "active"
      //                       ? "bg-green-100 text-green-800"
      //                       : "bg-gray-100 text-gray-800"
      //                   }`}
      //                 >
      //                   {assignment.status}
      //                 </span>
      //               </td>
      //               <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      //                 {new Date(assignment.startDate).toLocaleDateString()}
      //                 {console.log(assignment)}
      //               </td>
      //               <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      //                 {new Date(assignment.endDate).toLocaleDateString()}
      //               </td>
      //             </tr>
      //           ))}
      //         </tbody>
      //       </table>
      //     </div>
      //   </div>
      // )}*/}

      {statsViewer && (
        <StatsViewer {...statsViewer} onClose={() => setStatsViewer(null)} />
      )}
    </div>
  );
};

export default AdminDashboard;
