import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  // Polyline,
  // Popup,
  // CircleMarker,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const StatsViewer = ({
  type, // 'ad' or 'driver'
  adId = null,
  driverId = null,
  businessName = null,
  onClose,
}) => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [geotagCount, setGeotagCount] = useState(0);
  // const [mapLayers, setMapLayers] = useState([]);
  const mapLayers = useRef([])
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParam =
          type === "ad" ? `adId=${adId}` : `driverId=${driverId}`;
        const response = await fetch(`/adDriverstats?${queryParam}`);
        const result = await response.json();
        setData(result.data.stats);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [type, adId, driverId]);

  useEffect(() => {
    if (mapRef.current && selectedItem) {
      const getPolylinePositions = () => {
        if (!selectedItem?.geoTagPlays) return [];
        return formatGeotagPlays(selectedItem.geoTagPlays).map((play) => [
          play.lat,
          play.lng,
        ]);
      };
      const formatGeotagPlays = (geoTagPlays) => {
        return geoTagPlays.map((play) => {
          const [lat, long, timestamp, isMoving] = play.split("/");
          return {
            lat: parseFloat(lat),
            lng: parseFloat(long),
            timestamp: parseInt(timestamp),
            isMoving: isMoving === "true",
          };
        });
      };
      const map = mapRef.current;
      // Remove existing layers
      mapLayers.current.forEach((layer) => map.removeLayer(layer));
      mapLayers.current=[];
      // setMapLayers([]); // Clear the layer state
      // Create new layers based on selected item
      const newLayers = [];
      let lines = getPolylinePositions();
      let options = {
        color: selectedItem.isActive ? "blue" : "red",
        weight: 3,
      };
      // Add Polyline
      const polyline = L.polyline(lines, options).addTo(map);
      newLayers.push(polyline);

      // Add CircleMarkers
      const geotagPlays = selectedItem.geoTagPlays || [];
      formatGeotagPlays(geotagPlays).forEach((point, index) => {
        const circleMarker = L.circleMarker([point.lat, point.lng], {
          radius: 6,
          // fillColor: "#ffb400",
          fillColor: point.isMoving ? "green" : "red",
          color: point.isMoving ? "green" : "red",
          weight: 2,
          opacity:1,
          fillOpacity: 0.6,
        }).addTo(map);
        circleMarker.bindPopup(`${new Date(point.timestamp).toLocaleString()}
                      <br />
                      Status: ${point.isMoving ? "Moving" : "Stationary"}`)
        newLayers.push(circleMarker);
      });

      mapLayers.current = newLayers
      // setMapLayers(newLayers); // Update state with new layers
    }
  }, [selectedItem]);

  const handleItemClick = (item) => {
    // Update selected item and geotag count
    setSelectedItem(item);
    const geotagPlays = item.geoTagPlays || [];
    setGeotagCount(geotagPlays.length);
  };

  // const handleItemClick = (item) => {
  //   setSelectedItem(item);
  //   const geotagPlays = item.geoTagPlays || [];
  //   setGeotagCount(geotagPlays.length);
  // };

  // const formatGeotagPlays = (geoTagPlays) => {
  //   return geoTagPlays.map((play) => {
  //     const [lat, long, timestamp, isMoving] = play.split("/");
  //     return {
  //       lat: parseFloat(lat),
  //       lng: parseFloat(long),
  //       timestamp: parseInt(timestamp),
  //       isMoving: isMoving === "true",
  //     };
  //   });
  // };

  // const getPolylinePositions = () => {
  //   if (!selectedItem?.geoTagPlays) return [];
  //   return formatGeotagPlays(selectedItem.geoTagPlays).map((play) => [
  //     play.lat,
  //     play.lng,
  //   ]);
  // };

  // Click outside handler
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleBackdropClick}
    >
      <div
        className="bg-white rounded-lg w-11/12 h-5/6 flex overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Left Section */}
        <div className="w-1/3 border-r overflow-y-auto p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">
              {type === "ad" ? `Ad: ${businessName}` : `Driver: ${driverId}`}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-600 hover:text-gray-800"
            >
              ✕
            </button>
          </div>

          <div className="space-y-2">
            {data.length < 1 ? (
              <span> No data</span>
            ) : (
              data.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleItemClick(item)}
                  className={`p-3 rounded cursor-pointer hover:bg-gray-100 ${
                    selectedItem === item ? "bg-gray-100" : ""
                  }`}
                >
                  <span className={`${!item.isActive ? "text-red-500" : ""}`}>
                    {type === "ad"
                      ? `Driver ID: ${item.driverId}`
                      : `Business: ${item.businessName} (Ad: ${item.adId})`}
                  </span>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Right Section */}
        <div className="w-2/3 relative">
          <MapContainer
            ref={mapRef}
            center={[0, 0]}
            zoom={4}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {/* {selectedItem && (
              <Polyline
                positions={getPolylinePositions()}
                color={selectedItem.isActive ? "blue" : "red"}
                weight={3}
              >
                <Popup>
                  {type === "ad"
                    ? `Driver: ${selectedItem.driverId}`
                    : `Ad: ${selectedItem.adId}`}
                </Popup>
              </Polyline>
            )}
            {selectedItem?.geoTagPlays &&
              formatGeotagPlays(selectedItem.geoTagPlays).map(
                (point, index) => (
                  <CircleMarker
                    key={index}
                    center={[point.lat, point.lng]}
                    radius={6}
                    // fillColor={point.isMoving ? "ffb400" : "red"}
                    fillColor={"#ffb400"}
                    color={selectedItem.isActive ? "green" : "red"}
                    weight={2}
                    opacity={point.isMoving ? 1 : 0.3}
                    fillOpacity={0.6}
                  >
                    <Popup>
                      {new Date(point.timestamp).toLocaleString()}
                      <br />
                      Status: {point.isMoving ? "Moving" : "Stationary"}
                    </Popup>
                  </CircleMarker>
                )
              )} */}
          </MapContainer>
          {/* {geotagCount > 0 && ( */}
          <div className="absolute top-4 right-4 bg-white px-4 py-2 rounded shadow z-[9999]">
            Plays: {geotagCount}
          </div>
          {/*} )}*/}
        </div>
      </div>
    </div>
  );
};

export default StatsViewer;
